import React, {Fragment, useState, useEffect, useRef} from 'react';
import { BookingData } from '../Data/BookingData'
import FormElementSelect from '../../Form/Element/Select'
import booking from "../Booking";
import FormElementText from "../../Form/Element/Text";
import cx from "classnames";
import Button from "../../Form/Element/Button";
import './PreBookingInfo.scss'
import FormElementDatePicker from "../../Form/Element/DatePicker";
import TypeInfo from "./TypeInfo";

export default function TypeSelect (props) {
    const [showChildren, setShowChildren] = useState(props.booking.booking_type_category_id)
    const [updatingBookingTypeCategory, setUpdatingBookingTypeCategory] = useState(null)
    const [updatingBookingType, setUpdatingBookingType] = useState(null)
    const [coversCount, setCoversCount] = useState(props.booking.no_people ? parseInt(props.booking.no_people) : 0)
    const localDayPart = window.localStorage.getItem('selected_day_part');
    const [selectedDayPart, setSelectedDayPart] = useState(
        (localDayPart === null || localDayPart === undefined)
            ? null : localDayPart
    )
    const [loadingBookingTypeCategories, setLoadingBookingTypeCategories] = useState(false)
    const mounted = useRef(null)
    const [canUpdateNoPeople, setCanUpdateNoPeople] = useState(true)

    const toggleBookingTypeCategory = (bookingTypeCategory) => {
        setUpdatingBookingTypeCategory(bookingTypeCategory.id)
        BookingData.update({booking_type_category_id: bookingTypeCategory.id}, function() {
            setShowChildren(bookingTypeCategory.id)
            setUpdatingBookingTypeCategory(null)
            if (bookingTypeCategory.types.data.length === 1) {
                let type = bookingTypeCategory.types.data[0];
                selectPartyType(type.id)
            } else {
                selectPartyType(null)
            }
        })
    }

    const selectPartyType = (party_type_id) => {
        setUpdatingBookingType(party_type_id)
        BookingData.update({party_type: party_type_id}, function () {
            setUpdatingBookingType(null)
        })
    }

    const getError = (field) => {
        if (!booking[field]) {
            return null;
        }
        let error = BookingData.getFieldError(field);
        if (error) {
            return error.message;
        }
    }

    const changeCategory = (category_id) => {
        setUpdatingBookingTypeCategory(category_id)
        BookingData.update({booking_type_category_id: null}, function() {
            setShowChildren(null)
            setUpdatingBookingTypeCategory(null)
            selectPartyType(null)
        })
    }

    const changePartyType = (party_type_id) => {
        setUpdatingBookingType(party_type_id)
        BookingData.update({party_type: null}, function () {
            setUpdatingBookingType(null)
        })
    }

    const selectDayPart = (day_part_id) => {
        setLoadingBookingTypeCategories(true)
        setSelectedDayPart(day_part_id)
        if (BookingData.getBookingCategoryList().length > 0) {
            let categoryCount = 0;
            let chosenCategory = null;
            BookingData.getBookingCategoryList().filter(function (category) {
                category.day_parts.data.filter(function (day_part) {
                    if (day_part.id === parseInt(day_part_id)) {
                        categoryCount++;
                        chosenCategory = category
                    }
                })
            })
            if (categoryCount === 1) {
                toggleBookingTypeCategory(chosenCategory)
            }
        }
        window.localStorage.setItem('selected_day_part', day_part_id)
        setTimeout(function () {
            setLoadingBookingTypeCategories(false)
        }, 300)
    }

    const handleCoversChange = function(count) {
        setCanUpdateNoPeople(false)
        BookingData.update({no_people: count}, setCanUpdateNoPeople(true));
    }
    const handleDecrement = function() {
        BookingData.setUpdating()
        setCoversCount(prev => (!prev || prev <= 0) ? 0 : prev - 1);
    }
    const handleIncrement = function() {
        BookingData.setUpdating()
        setCoversCount(prev => prev + 1);
    }

    useEffect(() => {
        selectDayPart(selectedDayPart)
        if(!mounted.current) {
            mounted.current = true
            return
        }
        const debounce = setTimeout(() => {
            handleCoversChange(+coversCount <= 0 ? 0 : +coversCount);
        }, 1000);
        return () => clearTimeout(debounce);
    }, [coversCount]);

    let selected_booking_type_category = null;
    let day_parts = [];

    // Go through categories and collect day parts that are available for selection
    {BookingData.getBookingCategoryList().map(function (booking_type_category) {
        booking_type_category.day_parts.data.forEach(function (day_part) {
            var day_part_name = day_part.name;

            // Check if selected bar requires an override
            if (day_part.overrides.data.length > 0 && props.booking.bar) {
                day_part.overrides.data.forEach(function (dp) {
                    if (dp.bar_id === props.booking.bar.data.id) {
                        day_part_name = dp.part_name;
                    }
                })
            }
            day_parts[day_part.id] = {
                id: day_part.id,
                name: day_part_name,
                display_order: day_part.display_order
            };
        })
        if (parseInt(props.booking.booking_type_category_id) === parseInt(booking_type_category.id)) {
            selected_booking_type_category = booking_type_category;
        }
    })}

    let can_proceed = true;
    ['date', 'bar_id', 'no_people'].map((field) => {
        if (BookingData.getFieldError(field)) {
            can_proceed = false;
        }
        return field;
    });


    let buttonText = <span>{props.updating &&
        <span className="fa fa-spin fa-circle-o-notch" style={{marginRight: '6px'}}/>} Next</span>

    var maxDate = null;
    if (props.booking.bar && props.booking.bar.data.close_date) {
        maxDate = new Date(props.booking.bar.data.close_date);
    }
    return <Fragment>
        <FormElementSelect name="bar_id"
            disabled={props.updating || (BookingData.params.pre_filled_data.bar_id &&
             BookingData.params.pre_filled_data.force_location === true && props.booking.bar)}
            blankOptionText=" - Location - "
            onChange={(e) => BookingData.update({ bar_id: e.target.value })}
            values={BookingData.getBarList()} includeBlankOption={true}
            selectedValue={props.booking.bar ? props.booking.bar.data.id : ''}
        />

        <FormElementDatePicker name="date"
                               disabled={props.updating}
                               placeholder={props.placeholder || "Choose Date"}
                               filterDate={props.filterDate}
                               minDate={props.minDate}
                               openToDate={props.openToDate}
                               error={getError('date')}
                               maxDate={maxDate}
                               onChange={(e) => BookingData.update({date: e.target.value})}
                               value={props.booking.date ? props.booking.date : ''}
        />

        <FormElementText
            name="no_people"
            disabled={props.updating}
            number={true}
            placeholder="How Many People?"
            error={getError('no_people')}
            onChange={(e) => setCoversCount(e.target.value === '' ? 0 : +e.target.value)}
            value={coversCount > 0 ? coversCount : ''}
            append={
                <div className='no_people-buttons'>
                    <Button
                        disabled={props.updating && !canUpdateNoPeople}
                        text={<i className="fa fa-minus"/>}
                        name="add-product"
                        variant="circular"
                        onClick={handleDecrement}
                    />
                    <Button
                        disabled={props.updating && !canUpdateNoPeople}
                        text={<i className="fa fa-plus"/>}
                        name="add-product"
                        variant="circular"
                        onClick={handleIncrement}
                    />
                </div>
            }
        />

        <div className="field"></div>

        {props.booking.date !== null && props.booking.no_people !== null && props.booking.no_people > 0 && props.booking.bar &&
                <div className="day-part-selector" style={{padding: '15px 0'}}>
                    {day_parts.sort(function (dpa, dpb) {
                        if (dpa.display_order === dpb.display_order) {
                            return 0;
                        }
                        return dpa.display_order > dpb.display_order ? 1 : -1;
                    }).map(function (day_part) {
                        return <span onClick={() => selectDayPart(day_part.id)} className={cx('btn', {
                            'btn--outline': day_part.id !== parseInt(selectedDayPart),
                        })} key={day_part.id}>
                            {loadingBookingTypeCategories && selectedDayPart === day_part.id &&
                                <span className="fa fa-circle-o-notch fa-spin" style={{marginRight: '8px'}} />} {day_part.name}
                        </span>
                    })}
                </div>}

        {((props.booking.no_people === null || props.booking.no_people === 0) || props.booking.bar === null || props.booking.date === null) &&
            <Button text={buttonText} disabled={!can_proceed} name="continue"/>}

        {props.booking.date !== null && props.booking.no_people !== null && props.booking.no_people > 0 && props.booking.bar && selectedDayPart !== null &&
            <div id="type-select" style={{marginBottom: '15px', width: '100%'}}>

                {loadingBookingTypeCategories ? <div></div> : <div>
                    {BookingData.getBookingCategoryList().length > 0 ? BookingData.getBookingCategoryList().filter(function (category) {
                        let can_show = false;
                        category.day_parts.data.filter(function (day_part) {
                            if (day_part.id === parseInt(selectedDayPart)) {
                                can_show = true;
                            }
                        })
                        return can_show
                    }).sort(function (btcA, btcB) {
                        if (btcA.display_order === btcB.display_order) {
                            return 0;
                        }
                        return btcA.display_order > btcB.display_order ? 1 : -1;
                    }).map(function (booking_type_category) {
                        var booking_type_category_name = booking_type_category.name;
                        if (booking_type_category.overrides.data.length > 0) {
                            booking_type_category.overrides.data.forEach(function (btco) {
                                if (btco.bar_id === props.booking.bar.data.id) {
                                    booking_type_category_name = btco.category_name;
                                }
                            })
                        }
                        return <div className="panel panel-default" key={booking_type_category.id}>
                            <div className={cx('panel-heading', {
                                'panel-heading--selected': selected_booking_type_category !== null && booking_type_category.id === selected_booking_type_category.id && !updatingBookingTypeCategory !== selected_booking_type_category.id,
                                'panel-heading--not-selected': selected_booking_type_category !== null && booking_type_category.id !== selected_booking_type_category.id || selected_booking_type_category !== null && updatingBookingTypeCategory === selected_booking_type_category.id
                            })}
                                 key={booking_type_category.id}
                            >
                                <div className="panel-title--title" onClick={() => toggleBookingTypeCategory(booking_type_category)}>
                                    <h4 className="panel-title">
                                        {updatingBookingTypeCategory === booking_type_category.id && <span style={{marginRight: '5px'}} className="fa fa-spin fa-circle-o-notch"></span>}
                                        {booking_type_category_name}
                                    </h4>
                                </div>
                            </div>
                            {selected_booking_type_category !== null
                                && selected_booking_type_category.types.data.length > 1
                                && showChildren === selected_booking_type_category.id
                                && selected_booking_type_category.id === booking_type_category.id
                                && <ul className="list-group">
                                    {selected_booking_type_category.types.data.map(function (party_type) {
                                        return <li
                                            className={cx('list-group-item', {
                                                'list-group-item--selected': props.booking.party_type === party_type.id && !props.updating || party_type.id === updatingBookingType,
                                            })}
                                            key={selected_booking_type_category.id + '_' + party_type.id}
                                        >
                                            <div onClick={() => selectPartyType(party_type.id)} className="list-group-item--item type-select-button">
                                        <span className={cx('fa fa-lg', {
                                            'fa-chevron-right': props.booking.party_type === party_type.id && (updatingBookingType === null || party_type.id === updatingBookingType),
                                            'fa-circle-thin circle-icon': props.booking.party_type !== party_type.id && (updatingBookingType === null || party_type.id !== updatingBookingType),
                                        })} style={{marginRight: '8px'}} />
                                                {updatingBookingType === party_type.id && <span style={{marginRight: '5px'}} className="fa fa-spin fa-circle-o-notch"></span>}
                                                {party_type.front_end_name}
                                                {props.booking.party_type === party_type.id &&
                                                    (updatingBookingType === null || party_type.id === updatingBookingType) &&
                                                    <TypeInfo
                                                        nextButton={props.nextButton}
                                                        booking={props.booking}
                                                        updating={party_type.id === updatingBookingType}
                                                        hide_updating={true}
                                                    />}
                                            </div>
                                        </li>
                                    })}
                                </ul>}
                            {selected_booking_type_category !== null
                                && selected_booking_type_category.types.data.length === 1
                                && selected_booking_type_category.id === booking_type_category.id
                                && updatingBookingTypeCategory === null
                                && <ul className="list-group">
                                    <li className='list-group-item list-group-item--selected'>
                                        <div className="list-group-item--item type-select-button">
                                            <TypeInfo nextButton={props.nextButton} booking={props.booking} updating={props.updating} />
                                        </div>
                                    </li>
                                </ul>}
                        </div>}) : <div>There are no booking types available to choose from.</div>}
                </div>}

        </div>}
    </Fragment>
}